<template>
  <div class="box has-background-grey-dark">
    <div class="title-wrap">
      <h1 class="title mb-1">Yield Spreads</h1>
    </div>
    <Timestamp :timestamp-data="timestampData" />

    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th scope="col">Strategy</th>
            <th scope="col">Mid</th>
            <th scope="col">Change</th>
            <th scope="col">3PM Close</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="timestampData === 'Loading...'" colspan="4">
              <div class="container-load">
                <i class="fas fa-circle-notch fa-spin fa-5x"></i>
              </div>
            </td>
          </tr>
          <tr v-for="entry in gridData" :key="entry['Strategy']" >

            <td v-if="entry['Strategy']"
                class="has-tooltip-right"
                :class="{ strat_up: (entry['a_status'] ==='a_up'), strat_down: (entry['a_status'] === 'a_down')}" :data-tooltip="entry['strat_legs'].substring(0,20)">
              {{ entry['Strategy'] }}
            </td>
            <td v-if="entry['Mid']" class='mid has-tooltip-right' :data-tooltip="entry['strat_legs']">
              {{ entry['Mid'] }}
            </td>
            <td v-if="entry['Change']"
                class="has-tooltip-right"
                :class="{ inc_a : (entry['a_status'] === 'a_up') , dec_a: (entry['a_status'] === 'a_down') }" :data-tooltip="entry['strat_legs'].substring(0,20)" >
              {{ entry['Change'] }}
            </td>
            <td v-if="entry['3PM Close']" class="has-tooltip-right" :data-tooltip="entry['strat_legs'].substring(0,20)" >
              {{ entry['3PM Close'] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import Timestamp from '../../components/shared/Timestamp.vue';
import {io} from "socket.io-client";

const socket = io('https://draxjb.com/',{path: '/wss'});
socket.connect()

export default {
  components: {
    Timestamp
  },
  data() {
    return {
      gridColumns: ['Strategy', 'Mid', 'Change', '3PM Close'],
      gridData: Array,
      timestampData: 'Loading...'
    }
  },
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    socket.disconnect()
    socket.connect()
    socket.on('cashgrid', (data) => {
      if (data.user_id === 16) {
        this.gridData = JSON.parse(data.cashgrid)
        this.timestampData = data.lastupdated
      }
    })
        window.setInterval(() => {
          socket.emit('get_cash', this.user.id);
        }, 70)
  },
  beforeUnmount() {
    socket.disconnect()
  },
};
</script>

