<script>
export default {
  name: 'Notifications',
  props: {
    alertsObj: {},
  },
  setup(props) {},
}
</script>

<template>
  <div class="box has-background-grey-dark">
    <div class="title-wrap">
      <p class="title is-4">Alerts - Live Quotes</p>
    </div>
    <div class="timeline-wrapper">
      <div class="timeline-header"></div>
      <div class="timeline-wrapper-inner">
        <div class="timeline-container">

          <!--Timeline item-->
          <div v-for="(item, index) in alertsObj" :key="index" class="timeline-item is-unread">
            <!-- <div class="date">
              <span>{{ item.date }}</span>
            </div>
            <div class="dot is-warning"></div> -->
            <div class="content-wrap">
              <div class="content-box">
                <div class="status"></div>

                <div class="box-text">
                  <div class="meta-text">
                    <p>
                      <span>{{ item.name }} &nbsp;</span>
                      <a>{{ item.msg }}</a>
                    </p>
                    <span><small>{{ item.time }}, {{ item.date }}</small></span>
                  </div>
                </div>
                <div class="box-end">

                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="load-more-wrap has-text-centered">

        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../scss/abstracts/_variables.scss';
@import '../../scss/abstracts/_mixins.scss';

/*
  1. Timeline
  2. Timeline Dark mode
  3. Media Queries
*/

/* ==========================================================================
1. Timeline
========================================================================== */

.timeline-wrapper {
  max-width: 940px;
  margin: 0 auto;

  .timeline-wrapper-inner {
    padding-top: 20px;

    .timeline-container {
      .timeline-item {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        // &::before {
        //   content: '';
        //   position: absolute;
        //   top: 46px;
        //   left: 111px;
        //   height: 100%;
        //   width: 2px;
        //   background: $placeholder;
        //   z-index: 0;
        // }

        &:last-child {
          &::before {
            display: none;
          }
        }

        &.is-unread {
          .content-wrap {
            .content-box {
              .status {
                background: $h-red !important;
              }
            }
          }
        }

        .date {
          width: 80px;
          font-family: $font;
          text-align: right;

          span {
            font-size: 0.9rem;
            color: $light-text;
          }
        }

        .dot {
          position: relative;
          height: 14px;
          width: 14px;
          border-radius: $radius-rounded;
          border: 2.6px solid $primary;
          margin: 0 25px;
          z-index: 1;

          &.is-info {
            border-color: $info;
          }

          &.is-success {
            border-color: $success;
          }

          &.is-warning {
            border-color: $warning;
          }

          &.is-danger {
            border-color: $danger;
          }

          &.is-purple {
            border-color: $purple;
          }
        }

        .content-wrap {
          @include vuero-s-card();

          flex-grow: 2;

          .content-box {
            display: flex;
            align-items: center;

            .status {
              height: 8px;
              width: 8px;
              min-width: 8px;
              border-radius: $radius-rounded;
              background: $light-text;
              margin: 0 16px 0 0;
            }

            .box-text {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-left: 12px;
              flex-grow: 2;

              .meta-text {
                line-height: 1.2;

                p {
                  color: $light-text;

                  span {
                    font-family: $font-alt;
                    color: $title-grey;
                    font-weight: 600;
                  }

                  a {
                    color: $primary;
                  }

                  .tag {
                    position: relative;
                    top: -1px;
                    font-weight: 500;
                    line-height: 1.8;
                    height: 1.8em;
                    margin: 0 2px;
                  }
                }

                > span {
                  color: $light-text;
                  font-size: 0.9rem;
                }
              }
            }

            .box-end {
              margin-left: auto;

              .v-avatar {
                margin: 0 2px;
              }
            }
          }

          .meta-content {
            padding-left: 78px;
          }
        }
      }
    }

    .load-more-wrap {
      padding: 40px 0;

      .button {
        min-width: 240px;
        min-height: 50px;
        text-transform: uppercase;
        font-size: 0.85rem;
        font-weight: 500;
        color: $light-text;
      }
    }
  }
}

/* ==========================================================================
2. Timeline Dark mode
========================================================================== */

.is-dark {
  .timeline-wrapper {
    .timeline-wrapper-inner {
      .timeline-container {
        .timeline-item {
          &::before {
            background: $title-grey;
          }

          .content-wrap {
            @include vuero-card--dark();

            .content-box {
              .status {
                background: $title-grey;
              }

              .box-text {
                .meta-text {
                  p {
                    span {
                      color: $dark-dark-text;
                    }

                    a {
                      color: $primary;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .load-more-wrap {
        .button {
          background: $title-grey !important;
        }
      }
    }
  }
}

/* ==========================================================================
3. Media Queries
========================================================================== */

@media only screen and (max-width: 767px) {
  .timeline-wrapper {
    .timeline-wrapper-inner {
      padding-top: 0;

      .timeline-container {
        .timeline-item {
          flex-direction: column;

          &::before {
            display: none;
          }

          .dot {
            display: none;
          }

          .date {
            align-self: end;
            margin-bottom: 4px;
          }

          .content-wrap {
            .content-box {
              .box-end {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .timeline-wrapper {
    .timeline-wrapper-inner {
      padding-top: 0;

      .timeline-container {
        .timeline-item {
          flex-direction: column;

          &::before {
            display: none;
          }

          .dot {
            display: none;
          }

          .date {
            align-self: end;
            margin-bottom: 4px;
          }

          .content-wrap {
            .content-box {
              .box-end {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
